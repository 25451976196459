export interface UserFetch {
	page: number;
	limit: number;
}

export interface ListResponse {
	data: Array<Object>;
	page: number;
	limit: number;
	total: number;
}

export interface AuthStateProps {
	loggedIn?: boolean;
	token?: boolean;
	loginRequested?: boolean;
	error?: string;
	requesting?: boolean;
}

export interface StatusProps {
	status: string;
}

export interface LoginProps {
	identifier: string;
	password: string;
}

export const defaultFunction = (e: any) => {};
