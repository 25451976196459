import { Reducer } from "react";
import { SET_LAYOUT } from "Redux/Constants";

const INIT_STATE = {
  loading: false,
  error: "",
  data: false,
  update: false,
  form: false,
};

export interface UpdateLayoutType {
  data?: any;
  update?: boolean;
  form: any;
}

export const LayoutReducers: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    case SET_LAYOUT:
      return {
        ...state,
        data: action.payload.params?.data || false,
        update: action.payload.params?.update || false,
        form: action.payload.params?.form || false,
      };
    default:
      return { ...state };
  }
};

export default LayoutReducers;
