import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteChildrenProps, useHistory } from 'react-router';
import AuthRoute from 'Routes/AuthRoute';
import 'Themes/App.scss';
const AuthContainer = (props: RouteChildrenProps) => {
	const auth = useSelector((state: any) => state.AuthReducer);
	const history = useHistory();
	useEffect(
		() => {
			if (auth.token) {
				history.push('/');
			}
		},
		[ auth.token, history ]
	);
	return <AuthRoute {...props} />;
};

export default AuthContainer;
