import React from 'react';
import {  Card, Typography, Button, Result } from 'antd';
import {useHistory } from 'react-router-dom';

const CheckEmail = (props: any) => {
	const history = useHistory();
	const toLogin = () => {
		history.push('login');
	};
	return (
		<Card
			style={{
				boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.03)',
				borderRadius: '10px',
				margin: '40px 0px'
			}}
		>
			<Result
				status="success"
				title="Password Reset Successful!"
				subTitle={<Typography.Paragraph>Please Check your email <Typography.Link strong>{props?.email}</Typography.Link></Typography.Paragraph>}
			/>
			<Button style={{ marginTop: 20 }} block type="link" onClick={() => toLogin()}>
						LOGIN
					</Button>
		</Card>
	);
};

export default CheckEmail;
