import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { history, configureStore } from './Redux/AppStore';
import App from './Container/App';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from 'Themes/Theme';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={configureStore({})}>
			<ThemeProvider theme={lightTheme}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
