import { AuthLayout } from "Layouts/AuthLayout";
import { Redirect, Route, RouteChildrenProps, Switch } from "react-router";
import Login from "Views/Auth/Login";
import ResetPassword from "Views/Auth/ResetPassword";
export const AuthRoute = (props: RouteChildrenProps) => {
  return (
    <AuthLayout>
      <Switch>
      <Route path={`${props?.match?.path}/login`} exact component={Login} />
      <Route path={`${props?.match?.path}/reset-password`} exact component={ResetPassword} />
      <Redirect to={`${props?.match?.path}/login`} />
    </Switch>
    </AuthLayout>
  );
};

export default AuthRoute;
