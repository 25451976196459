import React from 'react';
import { Card, Typography, Divider, Form, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const NewPassword = (props: any) => {
	const history = useHistory();
	const toLogin = () => {
		history.push('login');
	};
	return (
		<Card
			style={{
				boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.03)',
				borderRadius: '10px',
				margin: '40px 0px'
			}}
		>
			<Typography.Title level={3} style={{ textAlign: 'center' }}>
				New Password
			</Typography.Title>
			<Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

			<Form layout="vertical">
				<Form.Item
					style={{ marginTop: 40, marginBottom: 40 }}
					label="New Password"
					name="password"
					rules={[ { required: true, message: 'Email is Required' } ]}
				>
					<Input.Password placeholder="New Password" />
				</Form.Item>
				<Form.Item
					style={{ marginTop: 40, marginBottom: 40 }}
					label="New Password"
					name="confirmPassword"
					rules={[ { required: true, message: 'Email is Required' } ]}
				>
					<Input.Password placeholder="New Password" />
				</Form.Item>

				<Form.Item>
					<Button block type="primary" htmlType="submit">
						SUBMIT
					</Button>
					<Button style={{ marginTop: 20 }} block type="link" onClick={() => toLogin()}>
						LOGIN
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default NewPassword;
