import ContentLayout from "Layouts/ContentLayout";
import React from "react";
import { RouteChildrenProps } from "react-router";
import ProtectedRoute from "Routes/ProtectedRoute";
import "Themes/App.scss";
const SuperContainer = (props: RouteChildrenProps) => {
  return (
    <ContentLayout>
      <ProtectedRoute {...props} />
    </ContentLayout>
  );
};

export default SuperContainer;
