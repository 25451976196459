import { Button, Card, Divider, Form, Input, Typography } from 'antd';
import { useHistory } from 'react-router';
import { defaultFunction } from 'Types/Types';
import Animate from 'rc-animate';

export interface ResetProps {
	onSuccess?: (e: any) => void;
}

export const ResetEmail: React.FC<ResetProps> = ({ onSuccess = defaultFunction }) => {
	const history = useHistory();
	const toLogin = () => {
		history.push('login');
	};
	const onSubmit = (value: any) => {
		onSuccess(value.email);
	};
	return (
		<Animate transitionName="fade" transitionAppear>
			<Card
				style={{
					boxShadow: '0 0 5px 5px rgba(0, 0, 0, 0.03)',
					borderRadius: '10px',
					margin: '40px 0px'
				}}
			>
				<Typography.Title level={3} style={{ textAlign: 'center' }}>
					Reset Password
				</Typography.Title>
				<Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }} />

				<Form onFinish={onSubmit} layout="vertical">
					<Form.Item
						style={{ marginTop: 40, marginBottom: 40 }}
						label="Email"
						name="email"
						rules={[ { required: true, message: 'Email is Required' } ]}
					>
						<Input type="email" placeholder="Email" />
					</Form.Item>

					<Form.Item>
						<Button block danger type="primary" htmlType="submit">
							RESET PASSWORD
						</Button>
						<Button style={{ marginTop: 20 }} block type="link" onClick={() => toLogin()}>
							LOGIN
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</Animate>
	);
};

export default ResetEmail;
