import AuthContainer from 'Container/AuthContainer';
import SuperContainer from 'Container/SuperContainer';
import { Redirect, Route, Switch } from 'react-router';

export const MainRoute = () => {
	return (
		<Switch>
			<Route path="/auth" component={AuthContainer} />
			<Route path="/" component={SuperContainer} />
			<Redirect to="/" />
		</Switch>
	);
};
export default MainRoute;
