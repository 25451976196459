import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';

export const AuthLayout = (props: any) => {
	return (
		<Layout>
			<Content>
				<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
					<Col span={6}>{props.children}</Col>
				</Row>
			</Content>
		</Layout>
	);
};
